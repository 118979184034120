var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"news"},[_c('div',{staticClass:"news_wrap"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"carousel"},[_c('el-carousel',{attrs:{"height":"320px"}},_vm._l((_vm.newList),function(item,index){return _c('el-carousel-item',{directives:[{name:"show",rawName:"v-show",value:(index < 4),expression:"index < 4"}],key:index,staticClass:"cursor"},[_c('h3',{staticClass:"small"},[_vm._v(_vm._s(item.title))]),_c('img',{staticClass:"err_img",attrs:{"src":item.thumbnail,"alt":item.title},on:{"click":function($event){return _vm.goRouter(item.id)}}})])}),1)],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v("新闻动态")]),_c('a',{staticClass:"more",attrs:{"href":"javascript:void(0);","title":"查看更多"},on:{"click":function($event){return _vm.$router.push({
                  path: '/news/new_list/',
                })}}},[_c('span',[_vm._v("查看更多")]),_c('i',{staticClass:"icon"})])]),_c('div',{staticClass:"content"},[_c('ul',_vm._l((_vm.newList.slice(0, 8)),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toNewsDetail(item.id)}}},[_c('a',{staticClass:"line_clamp1",attrs:{"href":"#"}},[(item.hasTop == 1)?_c('span',{staticClass:"hot"},[_vm._v("置顶")]):_vm._e(),_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v("政策解读")]),_c('a',{staticClass:"more",attrs:{"href":"javascript:;","title":"查看更多"},on:{"click":function($event){return _vm.$router.push({
                  path: '/news/new_list/',
                 query: { title: '兰州政策解读' },
                })}}},[_c('span',[_vm._v("查看更多")]),_c('i',{staticClass:"icon"})])]),_c('div',{staticClass:"content"},[_c('ul',_vm._l((_vm.policyList.slice(0, 8)),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toNewsDetail(item.id)}}},[_c('a',{staticClass:"line_clamp1",attrs:{"href":"#"}},[_c('i',{staticClass:"icon"}),_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v("政策法规")]),_c('a',{staticClass:"more",attrs:{"href":"javascript:;","title":"查看更多"},on:{"click":function($event){return _vm.$router.push({
                  path: '/news/policy_list/',
                })}}},[_c('span',[_vm._v("查看更多")]),_c('i',{staticClass:"icon"})])]),_c('div',{staticClass:"content"},[_c('ul',_vm._l((_vm.lawList.slice(0, 8)),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toNewsDetail(item.id)}}},[_c('a',{staticClass:"line_clamp1",attrs:{"href":"#"}},[_c('i',{staticClass:"icon"}),_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v("通知公告")]),_c('a',{staticClass:"more",attrs:{"href":"javascript:;","title":"查看更多"},on:{"click":function($event){return _vm.$router.push({
                  path: '/news/msg_list/'
                })}}},[_c('span',[_vm._v("查看更多")]),_c('i',{staticClass:"icon"})])]),_c('div',{staticClass:"content"},[_c('ul',_vm._l((_vm.noticeList.slice(0, 8)),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toNewsDetail(item.id)}}},[_c('a',{staticClass:"line_clamp1",attrs:{"href":"#"}},[_c('i',{staticClass:"icon"}),_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }