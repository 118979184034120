<template>
  <section>
    <div class="news">
      <div class="news_wrap">
        <div class="one">
          <div class="carousel">
            <el-carousel height="320px">
              <el-carousel-item
                v-for="(item, index) in newList"
                :key="index"
                v-show="index < 4"
                class="cursor"
              >
                <h3 class="small">{{ item.title }}</h3>
                <img
                  :src="item.thumbnail"
                  :alt="item.title"
                  class="err_img"
                  @click="goRouter(item.id)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="box">
            <div class="title">
              <h2>新闻动态</h2>
              <a
                href="javascript:void(0);"
                @click="
                  $router.push({
                    path: '/news/new_list/',
                  })
                "
                title="查看更多"
                class="more"
                ><span>查看更多</span><i class="icon"></i
              ></a>
            </div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in newList.slice(0, 8)"
                  @click="toNewsDetail(item.id)"
                  :key="index"
                >
                  <a href="#" class="line_clamp1"
                    ><span v-if="item.hasTop == 1" class="hot">置顶</span>{{ item.title }}</a
                  >
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="two">
          <div class="box">
            <div class="title">
              <h2>政策解读</h2>
              <a
                href="javascript:;"
                @click="
                  $router.push({
                    path: '/news/new_list/',
                   query: { title: '兰州政策解读' },
                  })
                "
                title="查看更多"
                class="more"
                ><span>查看更多</span><i class="icon"></i
              ></a>
            </div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in policyList.slice(0, 8)"
                  @click="toNewsDetail(item.id)"
                  :key="index"
                >
                  <a href="#" class="line_clamp1"
                    ><i class="icon"></i>{{ item.title }}</a
                  >
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="box">
            <div class="title">
              <h2>政策法规</h2>
              <a
                href="javascript:;"
                @click="
                  $router.push({
                    path: '/news/policy_list/',
                  })
                "
                title="查看更多"
                class="more"
                ><span>查看更多</span><i class="icon"></i
              ></a>
            </div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in lawList.slice(0, 8)"
                  @click="toNewsDetail(item.id)"
                  :key="index"
                >
                  <a href="#" class="line_clamp1"
                    ><i class="icon"></i>{{ item.title }}</a
                  >
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="box">
            <div class="title">
              <h2>通知公告</h2>
              <a
                href="javascript:;"
                @click="
                  $router.push({
                    path: '/news/msg_list/'
                  })
                "
                title="查看更多"
                class="more"
                ><span>查看更多</span><i class="icon"></i
              ></a>
            </div>
            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in noticeList.slice(0, 8)"
                  @click="toNewsDetail(item.id)"
                  :key="index"
                >
                  <a href="#" class="line_clamp1"
                    ><i class="icon"></i>{{ item.title }}</a
                  >
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: "政策要闻-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content:
            "兰州残疾人,兰州残疾人就业, 兰州残疾人找工作,兰州市残联,兰州残疾人福利,兰州残疾人政策",
      },
      {
        name: "description",
        content:
            "残疾人资讯平台为广大残疾人、残疾人工作者和更为广泛的关心、关注兰州市残疾人事业的群体提供全面、及时、准确的服务。",
      },
    ],
  },
  data() {
    return {
      newList: [],
      policyList: [],
      lawList: [],
      noticeList: [],
    };
  },
  components: {},
  methods: {
    toNewsDetail: function (id) {
      const { href } = this.$router.resolve({
        path: "/news/newDetail/",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    goRouter(id) {
      const { href } = this.$router.resolve({
        path: "/news/newDetail/",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //轮播
    swiper(val) {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          leve: "本省",
          city: "兰州",
          consultationClassName: val,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            if(val=="兰州新闻动态"){
              this.newList = res.data.data.records;
            }else if(val=="兰州政策解读"){
              this.policyList =res.data.data.records;
            }else if(val == "兰州政策法规"){
              this.lawList = res.data.data.records;
            }else{
              this.noticeList = res.data.data.records;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.swiper("兰州新闻动态");
    this.swiper("兰州政策解读");
    this.swiper("兰州政策法规");
    this.swiper("兰州通知公告");
    //判断是否移动端
    this.$mobile("https://lzmz.cdpee.org.cn/#/pages/information/index");
  },
};
</script>
<style lang="less">
.news {
  width: 1200px;
  margin: 10px auto 20px auto;
  .news_wrap {
    .one {
      display: flex;
      justify-content: space-between;
      .carousel {
        width: 796px;
        text-align: center;
        margin-right: 10px;
        h3 {
          position: absolute;
          font-size: 14px;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 40px;
          line-height: 30px;
          color: #fff;
        }
        img {
          width: 796px;
          height: 320px;
        }
      }
    }
    .two {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .box{
        // width: 393px;
        margin-right: 10px;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
    }
  }
  .box {
    flex: 1;
    background: #fff;
    padding: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      position: relative;
      &::after{
        content: "";
        background: #e9dddd;
        width: 393px;
        height: 1px;
        left: -10px;
        bottom: -11px;
        position: absolute;

      }
      h2 {
        font-size: 18px;
        border-left: 6px solid #00924b;
        padding-left: 10px;
      }

    }
    .content {
      margin-left: 10px;
      ul {
        li {
          display: flex;
          align-items: center;
          a {
            width: 260px;
            overflow: hidden;
            line-height: 34px;
            color: #333333;
          }
          .hot{
            font-size: 11px;
            color: white;
            padding: 1px 5px;
            border-radius: 2px;
            background: #FC6C38;
            margin-right: 6px;
            vertical-align: middle;
          }
          span {
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>

